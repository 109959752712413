import emoji from "react-easy-emoji";

import googlelogo from "./assets/img/icons/common/Nasmoco.jpg";
import github from "./assets/img/icons/common/beecons.png";
import airbnb from "./assets/img/icons/common/telkomsel.jpg";

export const greetings = {
	name: "Adi Siswoyo",
	title: "Hi all, I'm Adi Siswoyo",
	description:
		"A passionate Full Stack Web Developer and Mobile App Developer having an experience of building Web applications with Bootstrap / React Js. And I also have experience building cross platform mobile apps using React native and flutter",
	resumeLink: "https://drive.google.com/file/d/1DWjUQRsi0PrD-hZfyGkxTKCKlLyky16A/view?usp=sharing",
};

export const openSource = {
	githubUserName: "zeddinfo",
};

export const contact = {};

export const socialLinks = {
	facebook: "https://www.facebook.com/adii.s.752/",
	instagram: "https://www.instagram.com/adswy06/",
	twitter: "#",
	github: "https://github.com/zeddinfo",
	linkedin: "https://www.linkedin.com/in/adi-siswoyo-78a248151/",
};

export const skillsSection = {
	title: "What I do",
	subTitle:
		"CRAZY FULL STACK DEVELOPER WHO WANTS TO EXPLORE EVERY TECH STACK",
	skills: [
		emoji(
			"⚡ Develop highly interactive Front end / User Interfaces for your web and mobile applications"
		),
		emoji(
			"⚡ Progressive Web Applications ( PWA ) in normal and SPA Stacks"
		),
		emoji(
			"⚡ Highly Permofmance Aplications"
		),
	],

	softwareSkills: [
		{
			skillName: "html-5",
			fontAwesomeClassname: "vscode-icons:file-type-html",
		},
		{
			skillName: "css3",
			fontAwesomeClassname: "vscode-icons:file-type-css",
		},
		{
			skillName: "sass",
			fontAwesomeClassname: "logos:sass",
		},
		{
			skillName: "php",
			fontAwesomeClassname: "logos:php",
		},
		{
			skillName: "JavaScript",
			fontAwesomeClassname: "logos:javascript",
		},
		{
			skillName: "reactjs",
			fontAwesomeClassname: "vscode-icons:file-type-reactjs",
		},
		{
			skillName: "flutter",
			fontAwesomeClassname: "logos:flutter",
		},
		{
			skillName: "reactnative",
			fontAwesomeClassname: "vscode-icons:file-type-reactjs",
		},
		{
			skillName: "npm",
			fontAwesomeClassname: "vscode-icons:file-type-npm",
		},
		{
			skillName: "sql-database",
			fontAwesomeClassname: "vscode-icons:file-type-sql",
		},
		{
			skillName: "git",
			fontAwesomeClassname: "logos:git-icon",
		},
	],
};

export const SkillBars = [
	{
		Stack: "Frontend Developer", //Insert stack or technology you have experience in
		progressPercentage: "80", //Insert relative proficiency in percentage
	},
	{
		Stack: "Backend Developer",
		progressPercentage: "85",
	},
	{
		Stack: "Programming",
		progressPercentage: "80",
	},
];

export const educationInfo = [
	{
		schoolName: "SMK N 4 KENDAL",
		subHeader: "Computer ",
		duration: "June 2012 - June 2015",
		desc: "Majors that study computers (Hardware), Network devices, Network Management.",
		descBullets: [
			"Learn how to maintain a computer both hardware and software",
			"Learn to design, build, and manage a LAN, MAN, WAN network using Mikrotik",
		],
	},
	{
		schoolName: "Dian Nuwantoro University",
		subHeader: "Bachelor Degree of Informatics Engineering",
		duration: "September 2016 - Juli 2020",
		desc: "Creating a Website and Android-based Information System application at PT New Ratna Motor.",
		descBullets: [
			"Creating a Website-Based Ticket Booking Application.",
			"Internship at PT New Ratna Motor(September 2019 - February 2020) HRD Division.",
		],
	},
];

export const experience = [
	{
		role: "Web Developer Intern",
		company: "PT New Ratna Motor",
		companylogo: googlelogo,
		date: "September 2019 – Februari 2020",
		desc: "Internship to gain experience in the world of work.",
		descBullets: [
			"Creating a Website and Android-based Information System application at PT New Ratna Motor",
		],
	},
	{
		role: "Web Developer OJT",
		company: "PT Baracipta Esa Engineering",
		companylogo: github,
		date: "February 2020 – May 2020",
		desc: "Design, create supplier admin applications, create monetize features for sppliers.",
	},
	{
		role: "Fullstack Developer",
		company: "Putmasari Pratama (Telkomsel Authorized Dealer)",
		companylogo: airbnb,
		date: "September 2020 – Present",
		desc: "Role Informatics And Digital Business Department.",
		descBullets: [
			"Company BackOffice Application Maintenance",
			"Create an android-based reseller product sales application for topping up credit, PPOB, Game Top Up and Vocuher",
			"Creating Services API for Mobile-Based Attendance Applications",

		],

	},
];

export const projects = [
	{
		name: "Information System Sultan ADN Travel",
		desc: "Travel information system application, built using laravel and bootstrap",
		link: "https://travel.sultanadntravel.com/",
	},
	{
		name: "Primadana KSP Asset Management System",
		desc: "Website-based application built using Laravel, Bootstrap, Jquery, ajax and My SQL",
		github: "https://github.com/zeddinfo/simaset-project/tree/main/simaset",
	},
	{
		name: "Aplikasi Sistem Pengarsipan Surat Berbasis Website ",
		desc: "Website-based application built using Laravel 8",
		github: "https://github.com/zeddinfo/sipas",
	},
	{
		name: "Mobile-based e-commerce application",
		desc: "Mobile based Cross Platform app using flutter",
		github: "https://github.com/zeddinfo/sShamoOnlineShopFlutter",
	},
	{
		name: "Credit Sales Applications, Data Packages, PPOB, Mobile-based Game Vouchers",
		desc: "Mobile based Cross Platform app on using React Native",
		github: "https://github.com/zeddinfo/sShamoOnlineShopFlutter",
		link: "https://play.google.com/store/apps/details?id=com.isidw.app.typer",
	},
];

export const feedbacks = [
	{
		name: "Hassan Mehmood",
		feedback:
			"We have been very happy with our new website! It looks professional and very easy to navigate. Our experience with the customer service at Hanzla tauqeer has been great. They handle things very efficiently and are available for any questions we have. They also keep us updated on daily reports so we know how our site is doing. I would recommend that u have choose hanzla web developer services for u.",
	},
	{
		name: "Maheen Altaf",
		feedback:
			"the website is very nice, everything was perfectly made as i need. it just loads in moments, without giving u the chance to wait. the quality is also very amazing. i am using it without any problem. great job",
	},
];
